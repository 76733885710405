







































































import Vue from 'vue';
import { ISettingsDataservice } from '../../../shared/src/dataservices/ISettingsDataservice';
import { IMetadataService } from '../../../shared/src/services/IMetadataService';
import { IUserService } from '../../../shared/src/services/IUserService';

export default Vue.extend({
  inject: {
    categoryDataservice: 'categoryDataservice',
    settingsDataservice: 'settingsDataservice',
    metadataService: 'metadataService',
    userService: 'userService'
  },
  data: () => ({
    drawer: false as boolean
  }),
  mounted() {
    switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            this.drawer = false;
            break;
          case 'sm':
            this.drawer = true;
            break;
          case 'md':
            this.drawer = true;
            break;
          case 'lg':
            this.drawer = true;
            break;
          case 'xl':
            this.drawer = true;
            break;
        }
  },
  watch: {
    pagename: {
      immediate: true,
      handler(newValue) {
        ((this as any).metadataService as IMetadataService).setTitle(newValue);
      }
    }
  },
  computed: {
    pagename(): string {
      return ((this as any).settingsDataservice as ISettingsDataservice).shopName;
    },
    mini(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        case 'md': return false;
        case 'lg': return false;
        case 'xl': return false;
      }
    }
  },
  methods: {
      logout(): void {
        ((this as any).userService as IUserService).logout().then( (logoutSuccesfull) => {
          if (logoutSuccesfull) {
            this.$router.push({ name: 'login' });
          }
        });
      }
  },
});
